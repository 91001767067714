import { useCopy } from '@/compositions/copy';
import { usePopup } from '@/compositions/popup';
import { objectPropertyService } from '@/modules/common/api';
import { genMenuItemCopy, genMenuItemEdit } from '@/utils/list-generators';
import { typeCast } from '@/provider/utils';

export function useDefaultItemMenu() {
  const popup = usePopup();
  const { copyWithAlert } = useCopy();

  return {
    genMenu: property => {
      const { id, value, type } = property;
      return [
        genMenuItemEdit(() => {
          // check that property is position property
          if (value.lat !== undefined && value.lon !== undefined) {
            const { lat, lon } = value;
            popup.open({
              component: () =>
                import('@/components/popup/PopupCoordinateEdit.vue'),
              props: {
                lat,
                lon,
                hideAlt: true,
                onConfirm: value =>
                  objectPropertyService.update(id, {
                    value: {
                      lon: Number(value.lon),
                      lat: Number(value.lat)
                    }
                  })
              }
            });
          } else {
            popup.open({
              component: () => import('@/components/popup/PopupValueEdit.vue'),
              props: {
                valueName: 'Value',
                value,
                onConfirm: async value => {
                  objectPropertyService.update(id, {
                    value: typeCast(type, value)
                  });
                }
              }
            });
          }
        }),
        genMenuItemCopy(async () => {
          copyWithAlert(value);
        })
      ];
    }
  };
}
