import { useCopy } from '@/compositions/copy';
import { usePopup } from '@/compositions/popup';
import { objectPropertyService } from '@/modules/common/api';
import { genMenuItemCopy, genMenuItemEdit } from '@/utils/list-generators';
import { formatCoordinates } from '../../../../../utils';

export function useCoordinatesItemMenu() {
  const popup = usePopup();
  const { copyWithAlert } = useCopy();

  return {
    genMenu: property => {
      const {
        id,
        value: { lat, lon, alt }
      } = property;
      return [
        genMenuItemEdit(() => {
          popup.open({
            component: () =>
              import('@/components/popup/PopupCoordinateEdit.vue'),
            props: {
              lat,
              lon,
              alt,
              onConfirm: value =>
                objectPropertyService.update(id, {
                  value: {
                    lon: Number(value.lon),
                    lat: Number(value.lat),
                    alt: Number(value.alt)
                  }
                })
            }
          });
        }),
        genMenuItemCopy(async () =>
          copyWithAlert(formatCoordinates({ lat, lon }))
        )
      ];
    }
  };
}
