import { useCopy } from '@/compositions/copy';
import { usePopup } from '@/compositions/popup';
import { objectPropertyService } from '@/modules/common/api';
import { genMenuItemCopy, genMenuItemEdit } from '@/utils/list-generators';

export function useRadiusItemMenu() {
  const popup = usePopup();
  const { copyWithAlert } = useCopy();

  return {
    genMenu: property => {
      const { id, value } = property;
      return [
        genMenuItemEdit(() => {
          popup.open({
            component: () => import('@/components/popup/EditProperty.vue'),
            props: {
              currentItem: property,
              onConfirm: value => objectPropertyService.update(id, { value })
            }
          });
        }),
        genMenuItemCopy(async () => copyWithAlert(value))
      ];
    }
  };
}
